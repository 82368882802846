import React from "react";
import { Container, Dropdown } from "react-bootstrap";
import userProfile from "../img/user.png";
import Cred from "../img/cred.png";
import ARA from "../img/ARA.png";
import Veriprroved from "../img/365.png";
import { useNavigate } from "react-router-dom";

export default function Header() {
  const username = JSON.parse(localStorage.getItem("userData"));
  const navigate = useNavigate();

  const handleLogout = () => {
    localStorage.clear();
    navigate("/");
  };
  return (
    <>
      <Container fluid className="p-0 header_sticky">
        <div className="hstack justify-content-between p-2 px-5 top_header">
          <img src={Cred} alt="Credit Acceptance" />
          <img src={ARA} alt="Credit Acceptance" />
          <img src={Veriprroved} alt="Credit Acceptance" />
        </div>
        <div className="header_section">
          <div className="hstack">
            <h3 className="m-0">CAC</h3>
            <div className="vr"></div>
            <div className="hstack justify-content-between w-100 px-3">
              <p className="m-0">Client Case Update</p>
              <div className="hstack gap-3" title="User Detail">
                <img src={userProfile} alt="User profile" />
                <div>
                  <p className="m-0">Welcome back!</p>
                  <Dropdown>
                    <Dropdown.Toggle
                      className="w-100 p-0 hstack"
                      variant="none"
                      id="dropdown-basic"
                    >
                      <h4 className="m-0">
                        {username?.firstName} {username?.lastName}
                      </h4>
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                      <Dropdown.Item onClick={handleLogout}>
                        <i className="fa-solid fa-right-from-bracket me-2"></i>
                        Logout
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Container>
    </>
  );
}
