import React, { useEffect, useState } from "react";
import Header from "../Component/Header";
import { Button, Col, Container, Form, Row } from "react-bootstrap";
import ListoFReport from "./ListOfReports";
import config from "../../config/config.json";
import { apiCall } from "../../services/ApiCall";
import { useNavigate } from "react-router-dom";

export default function Dashboard() {
  const navigate = useNavigate();
  const [caseList, setCaseList] = useState([]);
  const [fromDate, setFromDate] = useState("");
  const [toDate, setToDate] = useState("");
  const [searchQuery, setSearchQuery] = useState("");

  const handleFormSubmit = async (event) => {
    event.preventDefault();
    const { isSuccess, data } = await apiCall(
      `${config.POST_LOGIN_API_URL}serchGetCaseStatus`,
      {},
      {
        fromDate: fromDate,
        toDate: toDate,
        fileName: searchQuery,
      },
      "GET"
    );

    if (isSuccess) {
      const filteredResults = data?.data && data?.data.length ? data?.data.filter(item =>
        Object.values(item).some(value =>
          typeof value === 'string' && value.toLowerCase().includes(searchQuery.toLowerCase())
        )
      ) : [];
      setCaseList(filteredResults.reverse());
      setFromDate("");
      setToDate("");
      setSearchQuery("");
    }
  };

  const getCaseStatus = async () => {
    const { isSuccess, data } = await apiCall(
      `${config.POST_LOGIN_API_URL}getCaseStatus`,
      {},
      {},
      "GET"
    );
    if (isSuccess) {
      setCaseList(data?.data?.reverse());
    }
  };

  useEffect(() => {
    const token = localStorage.getItem("token");
    if(!token){
      navigate("/");
    }
    getCaseStatus();
  }, []);
  return (
    <>
      <Header />
      <Container size="lg">
        <section>
          <Form onSubmit={handleFormSubmit}>
            <Row className="Filter_View align-items-end">
              <Form.Group as={Col} md="3">
                <Form.Label>
                  <b>From Date</b>
                </Form.Label>
                <Form.Control
                  type="date"
                  value={fromDate}
                  onChange={(e) => setFromDate(e.target.value)}
                />
              </Form.Group>
              <Form.Group as={Col} md="3">
                <Form.Label>
                  <b>To Date</b>
                </Form.Label>
                <Form.Control
                  type="date"
                  value={toDate}
                  onChange={(e) => setToDate(e.target.value)}
                />
              </Form.Group>
              <Form.Group as={Col} md="6" className="search_result">
                <Form.Control
                  type="search"
                  placeholder="File/Report Name"
                  value={searchQuery}
                  onChange={(e) => setSearchQuery(e.target.value)}
                />
                <Button type="submit">Search</Button>
              </Form.Group>
            </Row>
          </Form>
        </section>
      
        <ListoFReport caseList={caseList}  />
      </Container>
    </>
  );
}
