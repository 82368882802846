import React, { useState } from "react";
import { Button, Card, Col, Container, Form, Nav, Row } from "react-bootstrap";
import config from "../../config/config.json";
import { apiCall } from "../../services/ApiCall";
import { useNavigate } from "react-router-dom";

export default function Login() {
  const navigate = useNavigate();
  const [validated, setValidated] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const handleSubmit = async (event) => {
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    } else {
      event.preventDefault();
      const { isSuccess, data } = await apiCall(
        `${config.AUTH_API_URL}login`,
        {},
        {
          email: email,
          password: password,
          portalType: "CLIENT",
        },
        "POST"
      );

      if (isSuccess) {
        localStorage.setItem("userData", JSON.stringify(data.data));
        localStorage.setItem("token", JSON.stringify(data.data.accessToken));
        navigate("/dashboard");
      }
    }
    setValidated(true);
  };

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  return (
    <>
      <Container fluid className="p-0">
        <div className="login-section">
          <div className="loginCard_section">
            <Card style={{ width: "30rem" }} className="p-4">
              <Card.Body>
                <Card.Title className="text-center">CAC</Card.Title>
                <Card.Title className="text-center">
                  <h4>Client-Sign In</h4>
                </Card.Title>
                <Card.Text>
                  <Form
                    noValidate
                    validated={validated}
                    onSubmit={handleSubmit}
                  >
                    <Row className="mb-3">
                      <Form.Group
                        as={Col}
                        md="12"
                        className="mb-3"
                        controlId="validationCustom01"
                      >
                        <Form.Label>
                          <b>Email</b>
                        </Form.Label>
                        <Form.Control
                          required
                          type="email"
                          placeholder="Email"
                          value={email}
                          onChange={(e) => setEmail(e.target.value)}
                        />
                      </Form.Group>
                      <Form.Group
                        as={Col}
                        md="12"
                        controlId="validationCustom02"
                      >
                        <Form.Label>
                          <b>Password</b>
                        </Form.Label>
                        <div className="password_manage">
                          <Form.Control
                            required
                            type={showPassword ? "text" : "password"}
                            placeholder="Password"
                            onChange={(e) => setPassword(e.target.value)}
                          />
                          <i
                            className={`fa-solid fa-eye${
                              showPassword ? "-slash" : ""
                            }`}
                            onClick={togglePasswordVisibility}
                            style={{ cursor: "pointer" }}
                          ></i>
                        </div>
                      </Form.Group>
                    </Row>
                
                    <Button type="submit" className="w-100">
                      Sign In
                    </Button>
                  </Form>
                </Card.Text>
              </Card.Body>
            </Card>
          </div>
        </div>
      </Container>
    </>
  );
}
